import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  Typography
} from "@mui/material";
import { FC, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HealthNowCardFront from "../assets/cards/HealthNowCardFront.png";
import { Card, CardStatus } from "../models";
import { useConfig } from "../utils/useConfig";
import { useData } from "../utils/useData";
import { SectionHeader } from "./SectionHeader";

export const CardActions: FC<{ card: Card }> = ({ card }) => {
  const navigate = useNavigate();
  const { config } = useConfig();
  const { userInfoData, refetch } = useData();
  const { getToken } = useKindeAuth();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [error, setError] = useState("");

  const mutation = useMutation({
    mutationFn: async (card: Card) => {
      const blockCard = card.status === CardStatus.ACTIVE;

      const response = await fetch(
        `${config?.API_URL}/employers/${userInfoData?.employerId}/cards/${card.id}/block`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            blockOption: blockCard ? "BLOCK" : "UNBLOCK"
          })
        }
      );

      if (!response.ok)
        throw new Error(
          `There was a problem ${
            blockCard ? "Blocking" : "Unblocking"
          } the card`
        );

      return card;
    },
    onSuccess: (card: Card) => {
      refetch();
      setIsConfirmationModalOpen(false);
      toast.success(
        `Card successfully ${
          card.status === CardStatus.ACTIVE ? "blocked" : "unblocked"
        }`
      );
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  if (!card) return null;

  return (
    <>
      <SectionHeader text="Your card" />
      <MuiCard variant="outlined" sx={{ maxWidth: "400px", mx: "auto" }}>
        <Box mx="auto" position="relative">
          <CardMedia
            component="img"
            width="100%"
            src={
              card?.cardDesignId && config?.PUBLIC_BUCKET_URL
                ? `${config.PUBLIC_BUCKET_URL}/card-designs/${card.cardDesignId}/normal.png`
                : HealthNowCardFront
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = HealthNowCardFront;
            }}
            alt="HealthNow card"
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "20px",
              left: "15px"
            }}
          >
            <Typography sx={{ fontWeight: 500 }}>
              {card?.maskedCardNumber}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" gap={1} p={1}>
          {card.status === CardStatus.ACTIVE && (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/reset-pin")}
              >
                Reset Pin
              </Button>
            </>
          )}

          {(card.status === CardStatus.ACTIVE ||
            card.status === CardStatus.INACTIVE) && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => setIsConfirmationModalOpen(true)}
            >
              {card.status === CardStatus.ACTIVE
                ? "Block card"
                : "Unblock Card"}
            </Button>
          )}

          {(card.status === CardStatus.CREATED ||
            card.status === CardStatus.ISSUED) && (
            <Button
              component={Link}
              to="/activate-card"
              variant="outlined"
              color="secondary"
            >
              Activate card
            </Button>
          )}
        </Box>
      </MuiCard>

      <Dialog
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
      >
        <DialogTitle>
          {card.status === CardStatus.ACTIVE ? "Block card" : "Unblock Card"}
        </DialogTitle>
        <DialogContent>
          {error && <Typography color="error">{error}</Typography>}
          <DialogContentText>
            {card.status === CardStatus.ACTIVE
              ? "Are you sure you want to block your card? You will be unable to use it."
              : "Are you sure you want to unblock your card?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsConfirmationModalOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={mutation.isLoading}
            onClick={() => mutation.mutate(card)}
            autoFocus
            variant="contained"
            color="error"
          >
            {card.status === CardStatus.ACTIVE ? "Block card" : "Unblock Card"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
