import { PhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import { useMemo } from "react";
import * as yup from "yup";

// NOTE: Validation also exists in the customer portal
// Any changes here should be reflected there too...

const nameRegex = /^[a-zA-Z\d\s@'/,\-.]*$/;
const whitespaceRegex = /^(?! ).*[^ ]$/;
const addressRegex = /^[a-zA-Z0-9/@'., -āēīōūĀĒĪŌŪ]+$/;

const isMobileNumber = (
  phoneNumber: PhoneNumber,
  employeeCountryCode: string
) => {
  if (phoneNumber.country !== employeeCountryCode) return false; // If the derived phone numbers country code doesn't match the intended (employers)

  // Check the phone number matches the derived country format (eg. if its +64, next should be 2 for NZ)
  switch (phoneNumber.country) {
    case "NZ":
      return phoneNumber.nationalNumber.startsWith("2");
    case "AU":
      return (
        phoneNumber.nationalNumber.startsWith("4") ||
        phoneNumber.nationalNumber.startsWith("5")
      );
    default:
      return false;
  }
};

export const useDynamicEmployeeValidationSchema = (
  employeeCountryCode: any
) => {
  return useMemo(() => {
    const phoneNumberValidation = yup
      .string()
      .test(
        "is-valid-mobile-phone",
        `Invalid ${employeeCountryCode} mobile phone number`,
        function (value) {
          if (!value) return true; // Allow empty values
          try {
            const phoneNumber: PhoneNumber | undefined =
              parsePhoneNumberFromString(value, employeeCountryCode);
            if (!phoneNumber) {
              return false;
            }

            //const numberType = getNumberType(phoneNumber.nationalNumber, countryCode); -> doesn't work for some reason, tried with custom metadata (check project.json scripts)
            return (
              phoneNumber.isValid() &&
              isMobileNumber(phoneNumber, employeeCountryCode)
            );
          } catch (error) {
            return this.createError({
              message: `Invalid phone number for country: ${employeeCountryCode}`
            });
          }
        }
      );

    return yup.object().shape({
      firstName: yup
        .string()
        .max(100)
        .required()
        .matches(nameRegex, "Invalid characters in first name")
        .matches(
          whitespaceRegex,
          "First name cannot start or end with a space"
        ),
      lastName: yup
        .string()
        .max(100)
        .required()
        .matches(nameRegex, "Invalid characters in last name")
        .matches(whitespaceRegex, "Last name cannot start or end with a space"),
      cardDisplayName: yup
        .string()
        .max(28, "Card display name cannot be longer than 28 characters")
        .required("Card display name is required")
        .matches(nameRegex, "Invalid characters in card display name")
        .test(
          "at-least-one-space",
          "Card display name must contain at least one space and cannot start or end with a space",
          (value) => {
            if (value) {
              // Check if there is at least one space and the last character is not a space
              return (
                value.includes(" ") &&
                value[value.length - 1] !== " " &&
                value[0] !== " "
              );
            }
            return false; // If no value, then fail as value is required.
          }
        ),
      phone: phoneNumberValidation,
      email: yup
        .string()
        .email("Invalid email")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email") // Checks email domain (eg. example@google.com VALID, example@google INVALID)
        .test(
          "not-invalid",
          "Invalid email",
          (value) => !/^\S+\*|\S+@-/.test(value ?? "")
        )
        .required(),
      address1: yup
        .string()
        .max(100)
        .required()
        .matches(addressRegex, "Invalid characters in address"),
      address2: yup
        .string()
        .nullable()
        .max(100)
        .matches(addressRegex, "Invalid characters in address"),
      city: yup.string().required().max(100),
      stateOrProvince: yup.string().max(3),
      postalCode: yup.number().required(),
      country: yup.string().max(100).required()
    });
  }, [employeeCountryCode]);
};
