import { MenuItem, Menu, IconButton, Grid } from "@mui/material";
import BlueBannerLogo from "../assets/logos/BlueBanner.png";
import { useState } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logout } = useKindeAuth();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    handleClose();
  };

  // alternate option using AppBar
  // return (
  //   <Box sx={{ flexGrow: 1 }}>
  //     <AppBar>
  //       <Toolbar>
  //         <Box sx={{ flexGrow: 1 }}>
  //           <img src={BlueBannerLogo} alt="HeathNow logo" height={30} />
  //         </Box>
  //         <IconButton
  //           id="menu-button"
  //           aria-controls={open ? "navigation-menu" : undefined}
  //           aria-haspopup="true"
  //           aria-expanded={open ? "true" : undefined}
  //           onClick={handleClick}
  //         >
  //           <MenuIcon />
  //         </IconButton>
  //         <Menu
  //           id="navigation-menu"
  //           anchorEl={anchorEl}
  //           open={open}
  //           onClose={handleClose}
  //           MenuListProps={{
  //             "aria-labelledby": "menu-button",
  //           }}
  //         >
  //           <MenuItem onClick={handleLogout}>Sign out</MenuItem>
  //         </Menu>
  //       </Toolbar>
  //     </AppBar>
  //   </Box>
  // );

  return (
    <Grid container marginBottom={5}>
      <Grid item container xs={11} alignItems="center">
        <Link to="/" style={{ display: "flex" }}>
          <img src={BlueBannerLogo} alt="HeathNow logo" height={30} />
        </Link>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          id="menu-button"
          aria-controls={open ? "navigation-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="navigation-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "menu-button",
          }}
          onClick={handleClose}
        >
          <Link
            to="/profile"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem>Your account</MenuItem>
          </Link>
          <MenuItem onClick={handleLogout}>Sign out</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};
