import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Container } from "@mui/material";
import { useEffect } from "react";
import Zendesk from "react-zendesk";
import { Routes } from "./Routes";
import { Header } from "./components/Header";
import { LoadingSpinner } from "./components/LoadingSpinner";
import PWAPrompt from "./components/PWAPrompt";
import { useConfig } from "./utils/useConfig";

function App() {
  const { config } = useConfig();
  const { login, isAuthenticated, isLoading, error } = useKindeAuth();

  useEffect(() => {
    if (isAuthenticated || isLoading) return;
    login &&
      login({
        app_state: {
          redirectTo: window.location.pathname
        }
      });
  }, [isAuthenticated, login, isLoading]);

  if (isLoading || !isAuthenticated) return <LoadingSpinner />;

  if (error) return <p>{error}</p>;

  return (
    <Container maxWidth="sm" sx={{ paddingBottom: 4 }}>
      {config?.ZENDESK_KEY && (
        <Zendesk defer zendeskKey={config?.ZENDESK_KEY} />
      )}
      <PWAPrompt />
      <Header />
      <Routes />
    </Container>
  );
}

export default App;
