import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ArrowBack, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSessionStorage } from "usehooks-ts";
import { CodeInput } from "../../components/CodeInput";
import { CardStatus } from "../../models";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";

export const ActivateCard = () => {
  const navigate = useNavigate();
  const { getToken } = useKindeAuth();
  const { config } = useConfig();

  const { userInfoData, employee, cards } = useData();

  const defaultPinArray = Array.from({ length: 4 }, () => "");
  const [pin, setPin] = useState(defaultPinArray);
  const [confirmPin, setConfirmPin] = useState(defaultPinArray);
  const [showMessage, setShowMessage] = useState(true);
  const [error, setError] = useState("");
  const [page, setPage] = useState<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setShowCardActivatedSuccess] = useSessionStorage(
    "show-card-activated-success",
    false
  );

  const mutation = useMutation({
    mutationFn: async () => {
      // Never have more than 1 card with "CREATED" status
      const createdCard = cards.data?.items.find(
        (c) => c.status === CardStatus.CREATED || c.status === CardStatus.ISSUED
      );
      if (!createdCard) throw new Error("No card available to activate");

      const response = await fetch(
        `${config?.API_URL}/employers/${userInfoData?.employerId}/cards/${createdCard.id}/pin`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            pin: pin.join("")
          })
        }
      );

      if (!response.ok)
        throw new Error("There was a problem activating your card");
    },
    onSuccess: async () => {
      toast.success("Card successfully activated");
      setShowCardActivatedSuccess(true);
      await employee.refetch();
      await cards.refetch();
      navigate("/");
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (page < 1) {
      setPage(page + 1);
      return;
    }

    const pinString = pin.join("");

    if (pinString !== confirmPin.join("")) {
      setError("Pins do not match");
      setPage(0);
      setPin(defaultPinArray);
      setConfirmPin(defaultPinArray);
      return;
    }

    mutation.mutate();
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {showMessage && (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Typography variant="h6">
                    Great — you need to set a card PIN!
                  </Typography>
                }
                action={
                  <IconButton onClick={() => setShowMessage(false)}>
                    <Close />
                  </IconButton>
                }
                sx={{ pb: 0 }}
              />
              <CardContent>
                <Typography color="grey">
                  This PIN will be needed when using your card at the terminals
                  of service providers.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        {page > 0 && (
          <Grid item>
            <IconButton onClick={() => setPage(page - 1)}>
              <ArrowBack />
            </IconButton>
          </Grid>
        )}
        {error && (
          <Grid item>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          {page === 0 ? (
            <>
              <Typography variant="h6">Create Pin</Typography>
              <Typography>Use 4 numbers to create your PIN code</Typography>
              <Box display="flex" justifyContent="center" gap={2}>
                <CodeInput
                  code={pin}
                  setCode={(newPin) => {
                    setPin(newPin);
                    error && setError("");
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Typography>Enter PIN again</Typography>
              <Typography>Confirm the PIN you just created</Typography>
              <Box display="flex" justifyContent="center" gap={2}>
                <CodeInput code={confirmPin} setCode={setConfirmPin} />
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {page < 1 ? (
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={page === 0 && !pin.every((x) => x)}
            >
              Next
            </Button>
          ) : (
            <LoadingButton
              type="submit"
              loading={mutation.isLoading}
              variant="contained"
              fullWidth
              disabled={!confirmPin.every((x) => x)}
            >
              Save PIN
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
