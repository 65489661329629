export async function fetchData(
  endpoint: string,
  getToken?: () => Promise<string | undefined>,
  queryString?: string,
  pagination?: {
    page: number;
    pageSize: number;
  }
) {
  try {
    const accessToken = getToken ? await getToken() : null;

    const queryParams = [];
    if (queryString) {
      queryParams.push(queryString);
    }

    if (pagination) {
      if (pagination.page === 0 || pagination.page > 0)
      {
        queryParams.push(
          `page=${pagination.page}`
        );
      }
      if (pagination.pageSize > 0) {
        queryParams.push(
          `pageSize=${pagination.pageSize}`
        );
      }
    }

    const finalUrl = `${endpoint}${queryParams.length > 0 ? `?${queryParams.join("&")}` : ""}`;
    const response = await fetch(
      finalUrl,
      {
        headers: accessToken
          ? {
              Authorization: `Bearer ${accessToken}`
            }
          : undefined
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(`API request failed: ${error.message}`);
    } else {
      throw new Error(`API request failed with an unknown error`);
    }
  }
}
